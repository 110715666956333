@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
.header {
  display: flex;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  height: 120px;
  background-color: rgba(58, 155, 172, 0);
  font-size: 20px;
  color: white;
  font-family: "Poppins", sans-serif;
  align-items: center;
  position: sticky;
}

.header li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.li-button:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.header li:hover {
  cursor: pointer;
  color: black;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background-color: lightblue;
  height: 100vh;
  background-image: linear-gradient(
      rgba(75, 133, 219, 0.384),
      rgba(28, 121, 158, 0.342)
    ),
    url("./assets/hero\ turtle\ pools\ and\ spa.png");
  background-size: cover;
  background-position: center;
  margin-top: -120px;
}

.hero h1 {
  font-family: "Poppins", sans-serif;
  font-size: 70px;
  color: white;
  text-shadow: 2px 2px 4px #00000077;
  text-align: center;
}

.hero button {
  font-family: "Poppins";
  font-weight: 500;
  font-size: 36px;
  background-color: rgb(255, 0, 0);
  border: none;
  color: rgb(255, 255, 255);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wave {
  position: absolute;
  bottom: -10px;
  width: 100%;
  height: 150px; /* Adjust height as needed */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.main-content {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  font-family: "Poppins", sans-serif;
  justify-content: center;
}

.main-content strong {
  max-width: 50vw;
  text-align: center;
  font-size: 36px;
  font-weight: 400;
}

.main-content p {
  max-width: 60vw;
  text-align: center;
  font-size: 24px;
  font-weight: 300;
}

.suppliers img:hover {
  transform: scale(1.1);
  transition: 0.5s;
  cursor: pointer;
}

.suppliers {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.services {
  display: flex;

  gap: 15px;
}

.icons {
  display: flex;
  gap: 15px;
}

.sticky-mobile {
  display: none;
}

@media (max-width: 1280px) {
  .suppliers {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 995px) {
  .services {
    display: flex;
    flex-direction: column;
  }

  .icons {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 966px) {
  .header nav {
    display: none;
  }

  .wave {
    display: none;
  }
  .sticky-mobile {
    position: sticky;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
    padding: 10px;
  }

  .header {
    justify-content: center;
  }

  .hero h1 {
    font-size: 50px;
  }

  .hero button {
    font-size: 24px;
  }

  .main-content strong {
    font-size: 24px;
    max-width: 80vw;
  }

  .main-content p {
    font-size: 18px;
    max-width: 80vw;
  }
}
